
import {onMounted, ref, watch} from "vue";
import useCompany from "@/modules/companies/composables/useCompany";
import usePanel from "@/composables/usePanel";
const {companyForm} = useCompany();
export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()
        const name = ref('')
        const phone = ref('')
        const address = ref('')
        const status = ref('')
        onMounted(() => {
            name.value = companyForm.value.name
            phone.value = companyForm.value.phone
            address.value = companyForm.value.address
            status.value = companyForm.value.status
        })
        watch(name, val => companyForm.value.name = val)
        watch(phone, val => companyForm.value.phone = val)
        watch(address, val => companyForm.value.address = val)
        watch(status, val => companyForm.value.status = val)
        return {
            showPanel,
            togglePanel,
            name,
            phone,
            address,
            status,
            companyForm
        }
    }
}
